import type { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';

export type Nullable<T> = T | null | undefined;
export type OptionalString = Nullable<string>;
export type OptionalNumber = Nullable<number>;
export type OptionalBool = Nullable<boolean>;
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export type FunctionOf<F extends (...args: never[]) => void> = Function & F;

export enum ValidationStatus {
  VALID,
  INVALID,
  NOT_CHECKED,
}

export enum CancellationReason {
  CANDIDATE_REQUESTED_RESCHEDULE = 'Candidate Requested Reschedule',
  BUSINESS_REQUESTED_RESCHEDULE = 'Business Requested Reschedule',
  CANDIDATE_WITHDREW = 'Candidate Withdrew',
  POD_LOOP_NOT_FILLED = 'POD/Loop not Filled with Candidate',
  NO_BR_SECURED = 'No BR Secured',
  BUSINESS_GAP_INTERVIEWER = 'Business Gap In Interviewer Identification',
  LOOP_DETAIL_ERROR = 'Loop Detail Creation Error',
  OTHER = 'Other',
}

export type ValidationResult = {
  status: ValidationStatus;
  message: string | null;
};

export type TablePreferenceOptions = {
  pageSizes?: number[];
  stickyColumnsFirst?: number[];
  stickyColumnsLast?: number[];
  orderedColumnIds?: string[];
};

export type IElevateSplitPanel = {
  header?: Nullable<string | React.ReactNode>;
  content?: Nullable<React.ReactElement>;
};

export type NotificationType = FlashbarProps.Type;

export type NotificationMessage = {
  header?: React.ReactNode;
  content?: React.ReactNode;
};

export type ElevateNotification = NotificationMessage & {
  id: string;
  dismissible: boolean;
  type: NotificationType;
  onDismiss?: () => void;
};

export type TypedNotificationMessage = NotificationMessage & {
  type: NotificationType;
};

export enum DAYS_OF_WEEK {
  SUN = 1,
  MON = 2,
  TUE = 4,
  WED = 8,
  THU = 16,
  FRI = 32,
  SAT = 64,
}

export enum CollaborationType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum InterviewerView {
  CALIBRATION_SHEPHERD = 'CALIBRATION_SHEPHERD',
  MANAGER = 'MANAGER',
  ORGANIZATION = 'ORGANIZATION',
}

export enum PromoterView {
  PANEL_MEMBER = 'PANEL_MEMBER',
  REVIEWER = 'REVIEWER',
}
