import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import * as Auth from 'aws-amplify/auth';
import { ConsoleLogger } from 'aws-amplify/utils';
import { type Context, createContext, useContext } from 'react';

import { QueryKeys } from '@/backend/queryKeys';
import type { Nullable } from '@/common/models';
import { CACHE_KEY_AUTH_START_PATH } from '@/utilities/constants';
import type { AuthGroup } from '@/utilities/permissions';

const logger = new ConsoleLogger('CognitoQuery');

const RETRYABLE_ERRORS = new Set(['NoSessionFoundException', 'UserUnAuthenticatedException', 'NotAuthorizedException']);
const FEDERATED_IDENTITY_PROVIDER = 'AmazonFederate';

type IDTokenIdentity = {
  userId: string;
  providerName: 'AmazonFederate';
  providerType: 'OIDC';
  issuer: string | null;
  primary: 'true' | 'false';
  dateCreated: string;
};

type AccessTokenPayload = Auth.JWT['payload'] & {
  client_id: string;
  token_use: 'access';
  username: string;
};

type IDTokenPayload = Auth.JWT['payload'] & {
  'cognito:groups': (string | AuthGroup)[];
  email: string;
  family_name: string;
  given_name: string;
  identities: IDTokenIdentity[];
  isElevateAdmin: 'true' | 'false';
  token_use: 'id';
  validElevateUser: 'true' | 'false';
};

type CognitoTokens = {
  accessToken: { payload: AccessTokenPayload; jwtToken: string };
  idToken: { payload: IDTokenPayload; jwtToken: string };
};

export type ElevateAuthSession = {
  username: string;
  firstName: string;
  lastName: string;
  groups: string[];
  isElevateAdmin: boolean;
  validElevateUser: boolean;
};

function isAccessTokenPayload(v: Nullable<Auth.JWT['payload']>): v is AccessTokenPayload {
  return !!v && 'token_use' in v && v.token_use === 'access';
}

function isIDTokenPayload(v: Nullable<Auth.JWT['payload']>): v is IDTokenPayload {
  return !!v && 'token_use' in v && v.token_use === 'id';
}

export function signOut() {
  try {
    void Auth.signOut();
  } catch {
    /* empty */
  }
}

export async function refreshSession() {
  return Auth.fetchAuthSession({ forceRefresh: true });
}

function select(session: Nullable<CognitoTokens>): Nullable<ElevateAuthSession> {
  logger.debug('[start] select cognito token data', session);

  if (!session) {
    logger.info('[end] select cognito token data - no session data to select');
    return null;
  }
  const tokenPayload = session.idToken.payload;

  const result = {
    username: tokenPayload.identities[0].userId,
    firstName: tokenPayload.given_name,
    lastName: tokenPayload.family_name,
    groups: tokenPayload['cognito:groups'],
    isElevateAdmin: Boolean(tokenPayload.isElevateAdmin || 'false'),
    validElevateUser: Boolean(tokenPayload.validElevateUser || 'false'),
  };

  logger.debug('[end] select cognito token data', result);
  return result;
}

async function queryFn(): Promise<Nullable<CognitoTokens>> {
  try {
    // We don't want the output from this, but it throws a more reasonable error when the user isn't signed in
    await Auth.getCurrentUser();
    // Auth.fetchAuthSession() will automatically refresh the accessToken and idToken if expired.
    const { tokens } = await Auth.fetchAuthSession();
    // If a session is returned, we are authenticated, so set the status in the cache
    if (isIDTokenPayload(tokens?.idToken?.payload) && isAccessTokenPayload(tokens?.accessToken?.payload)) {
      return {
        accessToken: { payload: tokens.accessToken.payload, jwtToken: tokens.accessToken.toString() },
        idToken: { payload: tokens.idToken.payload, jwtToken: tokens.idToken.toString() },
      };
    }
    return null;
  } catch (ex) {
    if (ex instanceof Error && RETRYABLE_ERRORS.has(ex.name)) {
      /*
       The oAuth flow can start from any page, but drops the user on the homepage upon completion. Not great.
       Store the current location, so it can be restored after auth completes.
      */
      globalThis.dispatchEvent(new CustomEvent(CACHE_KEY_AUTH_START_PATH, { detail: globalThis.location.pathname }));
      await Auth.signInWithRedirect({ provider: { custom: FEDERATED_IDENTITY_PROVIDER } });
      /*
       `signInWithRedirect` starts the oAuth process, and returns before the session is finalized.
       We return null and the app handles this downstream.
      */
      return null;
    }
    logger.error(ex);
  }
  throw new Error('User is not authenticated!');
}

export type ICognitoQueryContext = UseQueryResult<Nullable<ElevateAuthSession>>;

// Using `as` here isn't ideal, but I can't figure out a better to deal with
// initial state. The value is checked when used via the hook to ensure it's not
// the same object and we throw an error if it is.
const emptyContext = {} as ICognitoQueryContext;
const CognitoQueryContext: Context<ICognitoQueryContext> = createContext(emptyContext);

export function CognitoQueryProvider({ children }) {
  const query = useQuery({ queryKey: QueryKeys.cognito, queryFn, staleTime: Infinity, select });

  return <CognitoQueryContext.Provider value={query}>{children}</CognitoQueryContext.Provider>;
}

export function useCognitoQuery(): ICognitoQueryContext {
  const context = useContext(CognitoQueryContext);

  if (context === emptyContext) {
    throw new Error('useCognitoQuery must be used within its provider');
  }

  return context;
}
